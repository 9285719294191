body {
  margin: 0;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #181818;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  /* scroll-behavior: smooth; */
}
html,
body {
  height: 100%;
  width: 100%;
  background-color: white;
}

* {
  box-sizing: border-box;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-slider {
  overflow: hidden;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  src:
    url('./fonts/avenir-medium.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: bold;
  src:
    url('./fonts/avenir-heavy.otf') format('opentype');
}
